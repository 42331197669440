import React from 'react';
import styled from 'styled-components';
import { Title } from '../common/title';

const Root = styled.div`
  flex-grow: 1;
  margin: 0 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Address = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Impressum: React.FC<{}> = () => (
  <Root>
    <Title>Herzlich Willkommen</Title>
    <div>
      auf meiner privaten Foto-Webseite. Schön, dass Du hier bist. Was kann ich für dich tun? Ich freue mich über eine
      Nachricht!
    </div>
    <Title>Kontakt</Title>
    <Address>
      Rafael Kullas
      <br />
      Neudorfer Str. 128
      <br />
      47057 Duisburg
      <br />
      E-Mail: rafael@kullas.photography
    </Address>
  </Root>
);

export default Impressum;
