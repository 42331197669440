import React from 'react';
import { graphql, PageProps } from 'gatsby';

import Layout from '../components/site-layout/site-layout';
import { Head } from '../components/site-layout/head';
import Impressum from '../components/impressum/impressum';

const ImpressumPage: React.FC<PageProps<GatsbyTypes.ImpressumQueryQuery>> = ({ data, location }) => (
  <Layout location={location} image={data.header?.childImageSharp?.gatsbyImageData}>
    <Head title="Impressum" />
    <Impressum />
  </Layout>
);

export default ImpressumPage;

export const pageQuery = graphql`
  query ImpressumQuery {
    header: file(relativePath: { eq: "images/impressum-header.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 4000)
      }
    }
  }
`;
